import React from 'react'
import Link from 'next/link'
import {useUser} from './hooks/useUser'

import {Icon} from './Icon'
import {useWhenMounted} from './hooks/useWhenMounted'
import {HStack, VStack} from '@components/Stack'

function Separator() {
  return (
    <span aria-hidden="true" style={{margin: '0 0.8em'}}>
      ·
    </span>
  )
}

function Footer() {
  const year = new Date().getFullYear()
  const isMounted = useWhenMounted()
  const user = useUser()

  return (
    <div className={`v2-footer`}>
      <HStack justify="center" gap="1rem" responsive align="center">
        <div className="footer__copyright">© {year} CSSBattle</div>

        <div className="footer__links">
          <HStack gap="1.5rem" justify="flex-end">
            <div
              style={{color: 'var(--clr-text-lightest-final)', fontWeight: 500}}
            >
              <span style={{fontWeight: 500, color: 'var(--clr-brand)'}}>
                410966
              </span>{' '}
              players
            </div>
            <a
              href="https://discord.gg/rvqvbcFM86"
              target="_blank"
              rel="external noopener noreferrer"
              className="hint--top-left"
              aria-label="Join on Discord"
            >
              <Icon marginRight="0" name="discord-alt" size="18" />
            </a>
            {/* <Separator /> */}
            <a
              href="https://bsky.app/profile/cssbattle.dev"
              target="_blank"
              rel="external noopener noreferrer"
              className="hint--top-left"
              aria-label="Join on Bluesky"
            >
              <Icon marginRight="0" name="bluesky" size="18" />
            </a>

            <a
              href="https://x.com/css_battle"
              target="_blank"
              rel="external noopener noreferrer"
              className="hint--top-left"
              aria-label="Join on X"
            >
              <Icon marginRight="0" name="twitter" size="18" />
            </a>
            {/* <Separator /> */}
            <a
              href="mailto:contact@cssbattle.dev"
              target="_blank"
              rel="external noopener noreferrer"
              className="hint--top-left"
              aria-label="Contact us"
            >
              <Icon marginRight="0" name="mail" size="18" />
            </a>
          </HStack>
        </div>
      </HStack>

      <HStack justify="space-between">
        <div className="footer__links">
          {/* {user && user.isPro && isMounted ? null : (
          <div style={{display: 'inline-block'}}> */}
          {/* above div is there bcoz of server rendering difference to difference from other <a>" */}

          <HStack gap="4rem" justify="center" align="flex-start">
            <VStack gap="0.75rem" align="flex-start">
              <h3 className="footer__column-title">Play</h3>
              <Link href="/battles">
                <a>Battles</a>
              </Link>
              <Link href="/daily">
                <a>Daily Targets</a>
              </Link>
              <Link href="/versus">
                <a>Versus</a>
              </Link>
            </VStack>
            <VStack gap="0.75rem" align="flex-start">
              <h3 className="footer__column-title">Learn</h3>
              <Link href="/blog">
                <a>Blog</a>
              </Link>
              <Link href="/learn">
                <a>CSS Courses</a>
              </Link>
              <Link href="/newsletter">
                <a>Newsletter</a>
              </Link>
              <a
                href="https://www.youtube.com/playlist?list=PLe7lg7RCOjYf3IAEO6152Elij3VUB8WSk"
                target="_blank"
              >
                Saturday Night Live
              </a>
            </VStack>

            <VStack gap="0.75rem" align="flex-start">
              <h3 className="footer__column-title">The Game</h3>
              <Link href="/tips">
                <a>Tips & Tricks</a>
              </Link>

              <Link href="/faqs">
                <a>FAQs</a>
              </Link>

              <a
                href="https://community.cssbattle.dev"
                target="_blank"
                rel="external noopener noreferrer"
              >
                Community
              </a>
            </VStack>

            <VStack gap="0.75rem" align="flex-start">
              <h3 className="footer__column-title">CSSBattle</h3>
              <Link href="/about">
                <a>About Us</a>
              </Link>

              <Link href="/hall-of-love">
                <a>Hall of ❤️</a>
              </Link>

              <Link href="/privacy-and-terms">
                <a>Privacy & Terms</a>
              </Link>

              <a href="/cssbattle-presskit.zip" download>
                Press Kit
              </a>
              <Link href="/advertise">
                <a>Advertise</a>
              </Link>
            </VStack>
          </HStack>
        </div>
      </HStack>

      <div className="footer__deco">
        <img src="/targets/3.png" />
        <img src="/targets/223.png" />
        <img src="/targets/224.png" />
        <img src="/targets/212.png" />
        <img src="/targets/1.png" />
        <img src="/targets/22.png" />
        <img src="/targets/23.png" />
        <img src="/targets/122.png" />
        <img src="/targets/221.png" />
      </div>
    </div>
  )
}

const FooterMemo = React.memo(Footer)
export {FooterMemo as Footer}
